import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

import { getSession } from "api/auth";
import { updateProfile } from "api/users";
import { ACTION_TYPE, useStore } from "store/StoreContext";

const Profile = () => {
  const [{ user }, dispatch] = useStore();
  const toast = useToast();

  const handleSubmit = async (values, actions) => {
    try {
      await updateProfile(user.id, values);
      const session = await getSession();

      dispatch({ type: ACTION_TYPE.SET_USER, payload: session });
      toast({
        title: "Profile updated successfully",
        status: "success",
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Unable to update profile",
        description: "Please try again",
        status: "error",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Flex>
      <Stack spacing={8} maxW={"lg"} py={12} px={6}>
        <Stack>
          <Heading fontSize={"4xl"}>Update your profile</Heading>
        </Stack>
        <Box bg={useColorModeValue("white", "gray.700")} p={8}>
          <Formik
            initialValues={{
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              phoneNumber: user.phoneNumber,
              address: user.address,
              uniqueIdentificationNumber: user.uniqueIdentificationNumber,
            }}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl id="email">
                        <FormLabel>Email</FormLabel>
                        <Input {...field} type="email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl id="firstName">
                        <FormLabel>First name</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.firstName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl id="lastName">
                        <FormLabel>Last name</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.lastName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="uniqueIdentificationNumber">
                    {({ field, form }) => (
                      <FormControl id="uniqueIdentificationNumber">
                        <FormLabel>UID</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.uniqueIdentificationNumber}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="address">
                    {({ field, form }) => (
                      <FormControl id="address">
                        <FormLabel>Address</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.address}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="phoneNumber">
                    {({ field, form }) => (
                      <FormControl id="phoneNumber">
                        <FormLabel>Phone Number</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.phoneNumber}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack spacing={10}>
                    <Button
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Update
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Profile;
