import { useEffect, useState } from "react";
import {
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  HStack,
  Tag,
  Flex,
  useToast,
} from "@chakra-ui/react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

import { useStore } from "store/StoreContext";
import { formatBAM } from "utils/helpers";
import { getStatistics } from "api/statistics";

export const Home = () => {
  const [{ yearsFilter }] = useStore();
  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState();
  const graphData = [
    { name: "2024-01-15", uv: 400, pv: 2400, amt: 2400 },
    { name: "2024-01-30", uv: 600, pv: 1400, amt: 1400 },
  ];
  const toast = useToast();

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await getStatistics({ year });
        setData(response);
      } catch (e) {
        toast({
          title: "Unable to load statistics",
          description: "Please try again",
          status: "error",
          isClosable: true,
          duration: 2000,
        });
      }
    };

    fetchStatistics();
  }, [toast, year]);

  const handleChangeYear = (y) => () => {
    setYear(y);
  };

  if (!data) {
    return null;
  }

  return (
    <Flex gap={5} direction="column">
      <HStack spacing={2} overflowY="scroll">
        {yearsFilter.toReversed().map((y) => (
          <Box key={y}>
            <Tag
              variant={y === year ? "solid" : "outline"}
              colorScheme="teal"
              onClick={handleChangeYear(y)}
              cursor="pointer"
            >
              {y}
            </Tag>
          </Box>
        ))}
      </HStack>
      <StatGroup>
        <Stat>
          <StatLabel>Number of tax payments</StatLabel>
          <StatNumber>{data.taxPaymentsCount}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Total tax payments amount</StatLabel>
          <StatNumber>{formatBAM(data.totalTaxPaymentsAmount)}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Tax payments amount</StatLabel>
          <StatNumber>{formatBAM(data.taxPaymentsAmount)}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Canton payments amount</StatLabel>
          <StatNumber>{formatBAM(data.cantonPaymentsAmount)}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Entity payments amount</StatLabel>
          <StatNumber>{formatBAM(data.entityPaymentsAmount)}</StatNumber>
        </Stat>
      </StatGroup>
      <LineChart
        width={600}
        height={300}
        data={graphData}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      >
        <Line type="monotone" dataKey="uv" stroke="#8884d8" />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </Flex>
  );
};

export default Home;
