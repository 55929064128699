import { useState, useEffect, useCallback } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";

import {
  createPensionInsurance,
  deletePensionInsurance,
  getPensionInsurancesForYear,
} from "api/pensionInsurances";
import PensionInsuranceCreate from "./PensionInsuranceCreate";
import PensionInsuranceFilter from "./PensionInsuranceFilter";
import PensionInsuranceList from "./PensionInsuranceList";

const PensionInsurances = () => {
  const [loading, setLoading] = useState();
  const [pensionInsurances, setPensionInsurances] = useState();
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
  });
  const toast = useToast();

  const fetchPensionInsurances = useCallback(async () => {
    try {
      setLoading(true);
      const { year } = filters;
      const response = await getPensionInsurancesForYear(year);
      setPensionInsurances(response);
    } catch (e) {
      toast({
        title: "Unable to load pension insurances",
        description: "Please try again",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [filters, toast]);

  useEffect(() => {
    fetchPensionInsurances();
  }, [fetchPensionInsurances]);

  const handleFiltersChange = (newFilters) => {
    setFilters({ ...filters, ...newFilters });
  };

  const handleCreate = async (values) => {
    await createPensionInsurance(values);
    await fetchPensionInsurances();
  };

  const handleDelete = (id) => async () => {
    try {
      await deletePensionInsurance(id);
      setPensionInsurances(pensionInsurances.filter((c) => c.id !== id));
      toast({
        title: "Pension insurance deleted successfully",
        status: "success",
      });
    } catch (e) {
      toast({
        title: "Unable to delete pension insurance",
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="5">
      <Flex justifyContent={{ sm: "flex-start", md: "flex-end" }}>
        <PensionInsuranceCreate onSubmit={handleCreate} />
      </Flex>
      <Box>
        <PensionInsuranceFilter
          filters={filters}
          onFilterChange={handleFiltersChange}
        />
      </Box>
      <Box>
        <PensionInsuranceList
          pensionInsurances={pensionInsurances}
          loading={loading}
          onDelete={handleDelete}
        />
      </Box>
    </Box>
  );
};

export default PensionInsurances;
