import humps from "humps";

import api from "./config";

export async function updateProfile(userId, data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.patch(`users/${userId}/`, body);
  return humps.camelizeKeys(response.data);
}

export async function createProfile(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post(`users/`, body);
  return humps.camelizeKeys(response.data);
}

export function exportDataURL(userId) {
  return `${api.getUri()}/users/${userId}/export-data`;
}
