import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import ClientForm from "./ClientForm";

const ClientCreate = ({ onSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      onClose();
    } catch (e) {
      toast({
        title: "Unable to create client",
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
        Add client
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new client</ModalHeader>
          <ModalCloseButton />
          <ClientForm
            values={{
              name: "",
              address: "",
              state: "",
              bankNumber: "",
              isGovernment: false,
              employmentHistory: [],
            }}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ClientCreate;
