import { Route, Routes } from "react-router-dom";

import Clients from "./Clients";

const ClientsRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<Clients />} />
    </Routes>
  );
};

export default ClientsRouter;
