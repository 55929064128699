import { useStore } from "store/StoreContext";
import AuthRoot from "./AuthRoot";
import PublicRoot from "./PublicRoot";

const Root = () => {
  const [store] = useStore();
  const { user } = store;

  if (user) {
    return <AuthRoot />;
  }

  return <PublicRoot />;
};

export default Root;
