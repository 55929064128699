import humps from "humps";

import api from "./config";

export async function getNotes(params) {
  const response = await api.get("notes/", { params });
  return humps.camelizeKeys(response.data);
}

export async function createNote(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("notes/", body);
  return humps.camelizeKeys(response.data);
}

export async function updateNote(noteId, data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.patch(`notes/${noteId}/`, body);
  return humps.camelizeKeys(response.data);
}

export async function deleteNote(noteId) {
  const response = await api.delete(`notes/${noteId}/`);
  return humps.camelizeKeys(response.data);
}
