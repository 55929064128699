export const BAM = new Intl.NumberFormat("bs-BA", {
  style: "currency",
  currency: "BAM",
  currencyDisplay: "narrowSymbol",
});

export const formatBAM = (value) =>
  `${BAM.format(value).replace("KM", "").trim()} KM`;

export const generateYearFilter = (fromDate) => {
  const today = new Date();
  const startYear = fromDate.getFullYear();
  const endYear = today.getFullYear();
  const years = [];
  for (let currentYear = startYear; currentYear <= endYear; currentYear++) {
    years.push(currentYear);
  }

  return years;
};
