import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Heading,
  Spinner,
  Stack,
  StackDivider,
  Tag,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { updateBillStatus, deleteBill, getBill } from "api/bills";
import { parseErrorResponse } from "api/helpers";
import { format } from "date-fns";
import { formatBAM } from "utils/helpers";
import {
  BILL_NEXT_STATUS,
  BILL_STATUS,
  BILL_STATUS_COLOR,
  BILL_STATUS_NAME,
} from "./constants";

const BillDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [bill, setBill] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    const fetchBill = async () => {
      try {
        setLoading(true);
        const result = await getBill(id);
        setBill(result);
      } catch (e) {
        const msg = parseErrorResponse(e, "Unable to fetch bill details");
        toast({
          title: msg,
          status: "error",
          isClosable: true,
          duration: 5000,
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBill();
    }
  }, [id, toast]);

  const handleNextStatus = async () => {
    try {
      const { status, paymentAt } = await updateBillStatus(id);
      setBill({ ...bill, status, paymentAt });
      toast({
        title: "Bill status updated successfully",
        status: "success",
      });
    } catch (e) {
      toast({
        title: "Unable to update bill status",
        description: "Please try again",
        status: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteBill(id);
      toast({
        title: "Bill deleted successfully",
        status: "success",
      });
      navigate("/bills");
    } catch (e) {
      toast({
        title: "Unable to delete bill",
        description: "Please try again",
        status: "error",
      });
    }
  };

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (!bill) {
    return null;
  }

  const billNextStatus = BILL_NEXT_STATUS[bill.status];

  return (
    <VStack spacing={4} align="stretch">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/bills">
            Bills
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{bill.slug}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader>
          <Heading size="md">Bill for {bill.clientName}</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Date
              </Heading>
              <Text pt="2" fontSize="sm">
                {format(new Date(bill.date), "yyyy LLL")}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Payment At
              </Heading>
              <Text pt="2" fontSize="sm">
                {bill.status !== BILL_STATUS.DRAFT
                  ? format(new Date(bill.paymentAt), "yyyy LLL dd")
                  : "-"}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Amount
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(bill.amount)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Status
              </Heading>
              <Box display="flex" justifyContent="space-between">
                <Text pt="2" fontSize="sm">
                  <Tag colorScheme={BILL_STATUS_COLOR[bill.status]}>
                    {BILL_STATUS_NAME[bill.status]}
                  </Tag>
                </Text>
                {billNextStatus && (
                  <Button colorScheme="teal" onClick={handleNextStatus}>
                    {billNextStatus}
                  </Button>
                )}
              </Box>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Delete
                </Heading>
                <Text pt="2" fontSize="sm">
                  Delete bill
                </Text>
              </Box>
              <Button colorScheme="red" onClick={onOpen}>
                Delete
              </Button>

              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete tax payment
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button colorScheme="red" onClick={handleDelete} ml={3}>
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default BillDetail;
