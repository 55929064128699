import humps from "humps";

import api from "./config";

export async function getNotifications() {
  const response = await api.get("notifications/");
  return humps.camelizeKeys(response.data);
}

export async function updateNotificationSeen(id, seen) {
  const body = humps.decamelizeKeys({ seen });
  const response = await api.put(`notifications/${id}/seen/`, body);
  return humps.camelizeKeys(response.data);
}

export async function notificationsCheck() {
  const response = await api.get("notifications/check/");
  return humps.camelizeKeys(response.data);
}
