import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Switch,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik } from "formik";

import ClientEmploymentHistory from "./ClientEmploymentHistory";

const ClientForm = ({ values, onSubmit, onCancel }) => {
  return (
    <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="name">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.name && form.touched.name}>
                  <FormLabel>Name</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="address">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.address && form.touched.address}
                >
                  <FormLabel>Address</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.address}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="state">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.state && form.touched.state}
                >
                  <FormLabel>State</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="bankNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.bankNumber && form.touched.bankNumber}
                >
                  <FormLabel>Bank Number</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.bankNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="isGovernment">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.isGovernment && form.touched.isGovernment
                  }
                >
                  <FormLabel>Government</FormLabel>
                  <Switch {...field} isChecked={field.value} />
                  <FormErrorMessage>
                    {form.errors.isGovernment}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <FieldArray
              name="employmentHistory"
              render={(arrayHelpers) => (
                <ClientEmploymentHistory
                  employmentHistory={props.values.employmentHistory}
                  onAdd={arrayHelpers.push}
                  onRemove={arrayHelpers.remove}
                />
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={props.isSubmitting}
            >
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ClientForm;
