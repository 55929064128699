import { useEffect, useState } from "react";
import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";

import {
  createClient,
  deleteClient,
  editClient,
  getClients,
} from "api/clients";
import ClientList from "./ClientList";
import ClientCreate from "./ClientCreate";
import ClientEdit from "./ClientEdit";

const Clients = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState();
  const [clientId, setClientId] = useState();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        setLoading(true);
        const result = await getClients();
        setClients(result);
      } catch (e) {
        toast({
          title: "Unable to load clients",
          description: "Please try again",
          status: "error",
          isClosable: true,
          duration: 2000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [toast]);

  const handleCreate = async (values) => {
    const result = await createClient(values);
    setClients([result, ...clients]);
  };

  const handleDelete = (id) => async () => {
    try {
      await deleteClient(id);
      setClients(clients.filter((c) => c.id !== id));
      toast({
        title: "Client deleted successfully",
        status: "success",
      });
    } catch (e) {
      toast({
        title: "Unable to delete client",
        description: "Please try again",
        status: "error",
      });
    }
  };

  const handleEdit = (id) => () => {
    setClientId(id);
    onEditOpen();
  };

  const handleEditSubmit = async (values) => {
    const result = await editClient(clientId, values);
    setClientId(null);
    onEditClose();
    setClients(
      clients.map((c) => {
        if (c.id === clientId) {
          return { ...c, ...result };
        }

        return c;
      })
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="5">
      <Flex justifyContent={{ sm: "flex-start", md: "flex-end" }}>
        <ClientCreate onSubmit={handleCreate} />
        <ClientEdit
          isOpen={isEditOpen}
          onClose={onEditClose}
          onSubmit={handleEditSubmit}
          id={clientId}
        />
      </Flex>
      <Box>
        <ClientList
          clients={clients}
          loading={loading}
          onDelete={handleDelete}
          onEdit={handleEdit}
        />
      </Box>
    </Box>
  );
};

export default Clients;
