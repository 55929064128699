import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { formatBAM } from "utils/helpers";

const GPD = ({ taxPayments }) => {
  if (!taxPayments) {
    return null;
  }

  const taxableAmount = taxPayments.reduce(
    (partialSum, a) => partialSum + a.taxableAmount,
    0
  );
  const taxPayment = taxPayments.reduce(
    (partialSum, a) => partialSum + a.taxPayment,
    0
  );
  const taxBase = taxPayments.reduce(
    (partialSum, a) => partialSum + a.taxBase,
    0
  );

  return (
    <Accordion allowMultiple>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              GPD
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <p>
            <b>Taxable amount</b> {formatBAM(taxableAmount)}
          </p>
          <p>
            <b>Tax payment</b> {formatBAM(taxPayment)}
          </p>
          <p>
            <b>Tax base</b> {formatBAM(taxBase)}
          </p>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default GPD;
