import { Center, Spinner } from "@chakra-ui/react";

const BackdropLoader = () => {
  return (
    <Center h="100vh" opacity="0.5">
      <Spinner color="black" />
    </Center>
  );
};

export default BackdropLoader;
