import { useCallback, useEffect, useState } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";

import { createNote, deleteNote, getNotes, updateNote } from "api/notes";
import NoteList from "./NoteList";
import NoteCreate from "./NoteCreate";
import NoteEdit from "./NoteEdit";

const Notes = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState();
  const [note, setNote] = useState();

  const fetchNotes = useCallback(async () => {
    try {
      setLoading(true);
      const { results } = await getNotes();
      setNotes(results);
    } catch (e) {
      toast({
        title: "Unable to load notes",
        description: "Please try again",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const handleCreate = async (values) => {
    const response = await createNote(values);
    setNotes([response, ...notes]);
  };

  const handleOpenEditModal = (noteId) => {
    const n = notes.find((n) => n.id === noteId);
    setNote(n);
  };

  const handleCloseEditModal = () => {
    setNote(null);
  };

  const handleUpdate = async (values) => {
    const response = await updateNote(note.id, values);
    setNotes((prev) =>
      prev.map((n) => (n.id === note.id ? { ...n, ...response } : n))
    );
    setNote(null);
  };

  const handleDelete = async () => {
    await deleteNote(note.id);
    setNotes(notes.filter((n) => n.id === note.id));
    setNote(null);
  };

  return (
    <Box display="flex" flexDirection="column" gap="5">
      <Flex justifyContent={{ sm: "flex-start", md: "flex-end" }}>
        <NoteCreate onSubmit={handleCreate} />
      </Flex>
      <Box>
        <NoteList
          notes={notes}
          loading={loading}
          onEdit={handleOpenEditModal}
        />
      </Box>
      <NoteEdit
        note={note}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
        onCancel={handleCloseEditModal}
      />
    </Box>
  );
};

export default Notes;
