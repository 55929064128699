import { useEffect, useState } from "react";
import {
  Center,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import { getClient } from "api/clients";
import ClientForm from "./ClientForm";
import { format } from "date-fns";

const ClientEdit = ({ isOpen, id, onSubmit, onClose }) => {
  const toast = useToast();
  const [client, setClient] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        setLoading(true);
        const response = await getClient(id);

        setClient({
          name: response.name,
          address: response.address,
          state: response.state,
          bankNumber: response.bankNumber,
          isGovernment: response.isGovernment,
          employmentHistory: response.employmentHistory.map((eh) => ({
            id: eh.id,
            startDate: format(new Date(eh.startDate), "yyyy-MM-dd"),
            endDate: eh.endDate
              ? format(new Date(eh.endDate), "yyyy-MM-dd")
              : undefined,
          })),
        });
      } catch (e) {
        toast({
          title: "Error fetching client",
          description: "Please try again",
          status: "error",
          isClosable: true,
          duration: 2000,
        });
        onClose();
      } finally {
        setLoading(false);
      }
    };

    if (isOpen && id) {
      fetchClient();
    } else {
      setClient(null);
    }
  }, [isOpen, id, toast, onClose]);

  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      onClose();
    } catch (e) {
      toast({
        title: "Unable to edit client",
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit client</ModalHeader>
        <ModalCloseButton />
        {loading && (
          <Center padding="5">
            <Spinner />
          </Center>
        )}
        {client && (
          <ClientForm
            values={client}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

export default ClientEdit;
