import { Route, Routes } from "react-router-dom";

import PensionInsurances from "./PensionInsurances";

const PensionInsuranceRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<PensionInsurances />} />
    </Routes>
  );
};

export default PensionInsuranceRouter;
