import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Textarea,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

const NoteForm = ({ values, onSubmit, onCancel, onDelete }) => {
  return (
    <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="title">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.title && form.touched.title}
                >
                  <FormLabel>Title</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="content">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.content && form.touched.content}
                >
                  <FormLabel>Title</FormLabel>
                  <Textarea {...field} />
                  <FormErrorMessage>{form.errors.content}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>
          <ModalFooter>
            {onDelete && (
              <Button mr={3} colorScheme="red" onClick={onDelete}>
                Delete
              </Button>
            )}
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={props.isSubmitting}
            >
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default NoteForm;
