import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Select,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

import { getClientsSearch } from "api/clients";

const BillForm = ({ values, onSubmit, onCancel }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const result = await getClientsSearch(true);
        setClients(result);
      } catch (e) {
        console.error(e);
      }
    };

    fetchClients();
  }, []);

  return (
    <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="client">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.client && form.touched.client}
                >
                  <FormLabel>Client</FormLabel>
                  <Select {...field} placeholder="Select client">
                    {clients.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.client}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="date">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.date && form.touched.date}>
                  <FormLabel>Date</FormLabel>
                  <Input {...field} type="month" />
                  <FormErrorMessage>{form.errors.startPeriod}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="amount">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.amount && form.touched.amount}
                >
                  <FormLabel>Amount</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={props.isSubmitting}
            >
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default BillForm;
