import { Box, Select } from "@chakra-ui/react";

import { useStore } from "store/StoreContext";

function TaxPaymentFilter({ filters, onFilterChange }) {
  const [{ yearsFilter }] = useStore();

  const handleSelectYear = (e) => {
    onFilterChange({ year: e.target.value });
  };

  return (
    <Box>
      <Select
        width={150}
        placeholder="Select year"
        value={filters.year}
        onChange={handleSelectYear}
      >
        {yearsFilter.map((yf) => (
          <option key={yf} value={yf}>
            {yf}
          </option>
        ))}
      </Select>
    </Box>
  );
}

export default TaxPaymentFilter;
