import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { parseErrorResponse } from "api/helpers";
import NoteForm from "./NoteForm";

const NoteCreate = ({ onSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      toast({
        title: "Note created successfully",
        status: "success",
      });
      onClose();
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Unable to create note"),
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
        Add note
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="4xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new note</ModalHeader>
          <ModalCloseButton />
          <NoteForm
            values={{
              title: "",
              content: "",
            }}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default NoteCreate;
