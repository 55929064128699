import { Box, Heading, SimpleGrid, useToast } from "@chakra-ui/react";

import SettingsItem from "./SettingsItem";
import { exportDataURL } from "api/users";
import { parseErrorResponse } from "api/helpers";
import { useStore } from "store/StoreContext";

const Settings = () => {
  const toast = useToast();
  const [{ user }] = useStore();

  const handleExport = async () => {
    try {
      const url = exportDataURL(user.id);
      window.open(url, "_blank", "noreferrer");
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Error exporting data"),
        status: "error",
      });
    }
  };

  return (
    <Box>
      <Heading marginBottom="10">Settings</Heading>
      <SimpleGrid spacing={4}>
        <SettingsItem
          title="Export data"
          description="Export payments and insurances CSV files"
          actionName="Export"
          onActionClick={handleExport}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Settings;
