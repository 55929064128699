import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import BillForm from "./BillForm";
import { parseErrorResponse } from "api/helpers";

const BillCreate = ({ onSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleSubmit = async (values) => {
    try {
      await onSubmit({
        ...values,
        date: new Date(values.date),
      });
      toast({
        title: "Bill created successfully",
        status: "success",
      });
      onClose();
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Unable to create bill"),
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
        Add bill
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new bill</ModalHeader>
          <ModalCloseButton />
          <BillForm
            values={{
              date: "",
              amount: "",
            }}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default BillCreate;
