import { useState, useEffect, useCallback } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";

import {
  createHealthInsurance,
  deleteHealthInsurance,
  getHealthInsurancesForYear,
} from "api/healthInsurances";
import HealthInsuranceFilter from "./HealthInsuranceFilter";
import HealthInsuranceList from "./HealthInsuranceList";
import HealthInsuranceCreate from "./HealthInsuranceCreate";

const HealthInsurances = () => {
  const [loading, setLoading] = useState();
  const [healthInsurances, setHealthInsurances] = useState();
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
  });
  const toast = useToast();

  const fetchTaxPayments = useCallback(async () => {
    try {
      setLoading(true);
      const { year } = filters;
      const response = await getHealthInsurancesForYear(year);
      setHealthInsurances(response);
    } catch (e) {
      toast({
        title: "Unable to load health insurances",
        description: "Please try again",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [filters, toast]);

  useEffect(() => {
    fetchTaxPayments();
  }, [fetchTaxPayments]);

  const handleFiltersChange = (newFilters) => {
    setFilters({ ...filters, ...newFilters });
  };

  const handleCreate = async (values) => {
    await createHealthInsurance(values);
    await fetchTaxPayments();
  };

  const handleDelete = (id) => async () => {
    try {
      await deleteHealthInsurance(id);
      setHealthInsurances(healthInsurances.filter((c) => c.id !== id));
      toast({
        title: "Health insurance deleted successfully",
        status: "success",
      });
    } catch (e) {
      toast({
        title: "Unable to delete health insurance",
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="5">
      <Flex justifyContent={{ sm: "flex-start", md: "flex-end" }}>
        <HealthInsuranceCreate onSubmit={handleCreate} />
      </Flex>
      <Box>
        <HealthInsuranceFilter
          filters={filters}
          onFilterChange={handleFiltersChange}
        />
      </Box>
      <Box>
        <HealthInsuranceList
          healthInsurances={healthInsurances}
          loading={loading}
          onDelete={handleDelete}
        />
      </Box>
    </Box>
  );
};

export default HealthInsurances;
