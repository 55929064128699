import { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Container,
  Text,
} from "@chakra-ui/react";

import { parseErrorResponse } from "api/helpers";
import NotificationList from "./NotificationList";
import { getNotifications, updateNotificationSeen } from "api/notifications";

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [notifications, setNotifications] = useState();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setLoading(true);
        setError(null);
        const { results } = await getNotifications();
        setNotifications(results);
      } catch (e) {
        const errorMsg = parseErrorResponse(e, "Something went wrong");
        setError(errorMsg);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  const markNotificationSeen = async (id) => {
    await updateNotificationSeen(id, true);
    setNotifications(
      notifications.map((n) => {
        if (n.id === id) {
          n.seen = true;
        }

        return n;
      })
    );
  };

  return (
    <Container>
      <Text fontSize="2xl" as="b">
        Notifications
      </Text>
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error fetching notifications</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <NotificationList
        notifications={notifications}
        loading={loading}
        onNotificationSeen={markNotificationSeen}
      />
    </Container>
  );
};

export default Notifications;
