import {
  Alert,
  AlertIcon,
  Center,
  Link,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import { formatBAM } from "utils/helpers";
import { BILL_STATUS, BILL_STATUS_COLOR, BILL_STATUS_NAME } from "./constants";

function BillList({ bills, loading }) {
  if (loading) {
    return (
      <Center marginTop={10}>
        <Spinner />
      </Center>
    );
  }

  if (!bills) {
    return null;
  }

  const billsLength = bills.length;

  if (billsLength === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        Bills not found!
      </Alert>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th />
            <Th>Date</Th>
            <Th>Client</Th>
            <Th>Payment At</Th>
            <Th isNumeric>Amount</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {bills.map((b, index) => (
            <Tr key={b.id}>
              <Td>
                <Link to={`/bills/${b.id}`} as={RouterLink} color={"blue.400"}>
                  {b.id}
                </Link>
              </Td>
              <Td>{format(new Date(b.date), "MMMM yyyy")}</Td>
              <Td>{b.clientName}</Td>
              <Td>
                {b.status !== BILL_STATUS.DRAFT
                  ? format(new Date(b.paymentAt), "PPP")
                  : "-"}
              </Td>
              <Td isNumeric>{formatBAM(b.amount)}</Td>
              <Td>
                <Tag colorScheme={BILL_STATUS_COLOR[b.status]}>
                  {BILL_STATUS_NAME[b.status]}
                </Tag>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default BillList;
