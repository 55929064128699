import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  Flex,
  Box,
  Stack,
  Link,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

import { parseErrorResponse } from "api/helpers";
import { createProfile } from "api/users";
import { getSession } from "api/auth";
import { ACTION_TYPE, useStore } from "store/StoreContext";

function Register() {
  const toast = useToast();
  const navigate = useNavigate();
  const [, dispatch] = useStore();

  const handleSubmit = async (values, actions) => {
    try {
      await createProfile(values);
      const user = await getSession();

      dispatch({ type: ACTION_TYPE.SET_USER, payload: user });
      navigate("/");
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Error creating account"),
        description: "Please try again",
        status: "error",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Create new account</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  <Field name="username">
                    {({ field, form }) => (
                      <FormControl id="username">
                        <FormLabel>Username</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.username}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="firstName">
                    {({ field, form }) => (
                      <FormControl id="firstName">
                        <FormLabel>First name</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.firstName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="lastName">
                    {({ field, form }) => (
                      <FormControl id="lastName">
                        <FormLabel>Last name</FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.lastName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl id="email">
                        <FormLabel>Email</FormLabel>
                        <Input {...field} type="email" />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="password">
                    {({ field, form }) => (
                      <FormControl id="password">
                        <FormLabel>Password</FormLabel>
                        <Input {...field} type="password" />
                        <FormErrorMessage>
                          {form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="confirmPassword">
                    {({ field, form }) => (
                      <FormControl id="confirmPassword">
                        <FormLabel>Confirm password</FormLabel>
                        <Input {...field} type="password" />
                        <FormErrorMessage>
                          {form.errors.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Stack>
                    <Button
                      bg={"blue.400"}
                      color={"white"}
                      _hover={{
                        bg: "blue.500",
                      }}
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Register
                    </Button>
                  </Stack>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    <Link color={"blue.400"} to={`/login`} as={RouterLink}>
                      Already have account?
                    </Link>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Register;
