import { useEffect, useState } from "react";
import { Stat, StatGroup, StatLabel, StatNumber } from "@chakra-ui/react";

import { getTaxPaymentStats } from "api/taxPayments";
import { formatBAM } from "utils/helpers";

export const TaxPaymentStats = ({ year }) => {
  const [stats, setStats] = useState();

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const result = await getTaxPaymentStats(year);

        setStats(result);
      } catch (e) {
        console.error(e);
      }
    };

    fetchStats();
  }, [year]);

  if (!stats) {
    return null;
  }

  return (
    <StatGroup>
      <Stat>
        <StatLabel>Total amount</StatLabel>
        <StatNumber>{formatBAM(stats.totalAmount)}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Tax payment</StatLabel>
        <StatNumber>{formatBAM(stats.totalTaxPayment)}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Canton payment</StatLabel>
        <StatNumber>{formatBAM(stats.totalTaxPaymentCanton)}</StatNumber>
      </Stat>
      <Stat>
        <StatLabel>Entity payment</StatLabel>
        <StatNumber>{formatBAM(stats.totalTaxPaymentEntity)}</StatNumber>
      </Stat>
    </StatGroup>
  );
};
