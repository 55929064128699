import { Route, Routes } from "react-router-dom";

import SidebarWithHeader from "components/navigation/Navigation";
import Home from "components/home/Home";
import Profile from "components/profile/Profile";
import HealthInsuranceRouter from "components/health-insurance/HealthInsuranceRouter";
import PensionInsuranceRouter from "components/pension-insurance/PensionInsuranceRouter";
import ClientsRouter from "components/clients/ClientsRouter";
import TaxPayments from "components/tax-payments/TaxPayments";
import TaxPaymentCreate from "components/tax-payments/TaxPaymentCreate";
import TaxPaymentDetail from "components/tax-payments/TaxPaymentDetail";
import Notifications from "components/notifications/Notifications";
import Settings from "components/settings/Settings";
import Bills from "components/bills/Bills";
import BillDetail from "components/bills/BillDetail";
import Notes from "components/notes/Notes";

function Root() {
  return (
    <SidebarWithHeader>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tax-payments" element={<TaxPayments />} />
        <Route path="/tax-payments/new" element={<TaxPaymentCreate />} />
        <Route path="/tax-payments/:slug" element={<TaxPaymentDetail />} />
        <Route
          path="/health-insurances/*"
          element={<HealthInsuranceRouter />}
        />
        <Route
          path="/pension-insurances/*"
          element={<PensionInsuranceRouter />}
        />
        <Route path="/clients/*" element={<ClientsRouter />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/bills" element={<Bills />} />
        <Route path="/bills/:id" element={<BillDetail />} />
        <Route path="/notes" element={<Notes />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </SidebarWithHeader>
  );
}

export default Root;
