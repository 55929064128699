import humps from "humps";

import api from "./config";

export async function getHealthInsurancesForYear(year) {
  const response = await api.get(`payments/health-insurances/year/${year}/`);
  return humps.camelizeKeys(response.data);
}

export async function createHealthInsurance(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("payments/health-insurances/", body);
  return humps.camelizeKeys(response.data);
}

export async function deleteHealthInsurance(id) {
  const response = await api.delete(`payments/health-insurances/${id}/`);
  return humps.camelizeKeys(response.data);
}

export async function getHealthInsurance(id) {
  const response = await api.get(`payments/health-insurances/${id}/`);
  return humps.camelizeKeys(response.data);
}
