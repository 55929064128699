import { Route, Routes } from "react-router-dom";

import HealthInsurances from "./HealthInsurances";

const HealthInsuranceRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<HealthInsurances />} />
    </Routes>
  );
};

export default HealthInsuranceRouter;
