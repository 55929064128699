import React, { useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";

import { getSession } from "api/auth";
import BackdropLoader from "components/BackdropLoader";
import { generateYearFilter } from "utils/helpers";

const INITIAL_STATE = {
  loading: false,
  user: undefined,
  yearsFilter: [],
};

const StoreContext = React.createContext();

export const ACTION_TYPE = {
  SET_LOADING: "set_loading",
  SET_USER: "set_user",
  REMOVE_USER: "remove_user",
};

const reducer = (draft, action) => {
  switch (action.type) {
    case ACTION_TYPE.SET_LOADING:
      draft.loading = action.payload;
      break;
    case ACTION_TYPE.SET_USER:
      const { earliestEmploymentStartDate } = action.payload;
      draft.user = action.payload;
      draft.yearsFilter = generateYearFilter(
        new Date(earliestEmploymentStartDate)
      );
      break;
    case ACTION_TYPE.REMOVE_USER:
      draft.user = null;
      draft.yearsFilter = [];
      break;
    default:
      break;
  }
};

export const StoreProvider = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [state, dispatch] = useImmerReducer(reducer, INITIAL_STATE);
  const value = [state, dispatch];

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const user = await getSession();

        dispatch({ type: ACTION_TYPE.SET_USER, payload: user });
      } catch (e) {
        console.error(e);
      } finally {
        setInitialized(true);
      }
    };

    if (!initialized) {
      fetchSession();
    }
  }, [initialized, dispatch]);

  if (!initialized) {
    return <BackdropLoader />;
  }

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const ctx = React.useContext(StoreContext);

  if (!ctx) {
    throw new Error("useStore must be used within the StoreProvider");
  }

  return ctx;
};
