import {
  Alert,
  AlertIcon,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { formatBAM } from "utils/helpers";
import { DeleteIcon } from "@chakra-ui/icons";

function PensionInsuranceList({ pensionInsurances, loading, onDelete }) {
  if (loading) {
    return (
      <Center marginTop={10}>
        <Spinner />
      </Center>
    );
  }

  if (!pensionInsurances) {
    return null;
  }

  const pensionInsurancesLength = pensionInsurances.length;

  if (pensionInsurancesLength === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        Pension insurances not found!
      </Alert>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Payment At</Th>
            <Th isNumeric>Amount</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {pensionInsurances.map((hi, index) => (
            <Tr key={hi.id}>
              <Td color={"blue.400"}>{pensionInsurancesLength - index}</Td>
              <Td>{format(new Date(hi.paymentAt), "MMMM")}</Td>
              <Td isNumeric>{formatBAM(hi.amount)}</Td>
              <Td>
                <Box display="flex" gap="10px" justifyContent="flex-end">
                  <IconButton
                    variant="ghost"
                    icon={<DeleteIcon color="red.400" focusable />}
                    onClick={onDelete(hi.id)}
                  />
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default PensionInsuranceList;
