import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import HealthInsuranceForm from "./HealthInsuranceForm";
import { parseErrorResponse } from "api/helpers";
import { INSERT_METHODS } from "utils/constants";

const HealthInsuranceCreate = ({ onSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleSubmit = async (values) => {
    try {
      await onSubmit({
        ...values,
        startPeriod: new Date(values.startPeriod),
        endPeriod: new Date(values.endPeriod),
      });
      toast({
        title: "Health insurances created successfully",
        status: "success",
      });
      onClose();
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Unable to create health insurance"),
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <>
      <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={onOpen}>
        Add health insurance
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new health insurance</ModalHeader>
          <ModalCloseButton />
          <HealthInsuranceForm
            values={{
              startPeriod: "",
              endPeriod: "",
              amount: "",
              insertMethod: INSERT_METHODS.RAISE,
            }}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

export default HealthInsuranceCreate;
