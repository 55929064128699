export const BILL_STATUS = {
  DRAFT: "draft",
  PAID: "paid",
  COMPLETED: "completed",
};

export const BILL_STATUS_NAME = {
  [BILL_STATUS.DRAFT]: "Draft",
  [BILL_STATUS.PAID]: "Paid",
  [BILL_STATUS.COMPLETED]: "Completed",
};

export const BILL_STATUS_COLOR = {
  [BILL_STATUS.DRAFT]: "blue",
  [BILL_STATUS.PAID]: "orange",
  [BILL_STATUS.COMPLETED]: "green",
};

export const BILL_NEXT_STATUS = {
  [BILL_STATUS.DRAFT]: "Pay",
  [BILL_STATUS.PAID]: "Complete",
  [BILL_STATUS.COMPLETED]: null,
};
