import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  Center,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";

const NotificationList = ({ notifications, loading, onNotificationSeen }) => {
  const handleNotificationSeen = (id) => () => {
    onNotificationSeen(id);
  };

  if (loading) {
    return (
      <Center marginTop={10}>
        <Spinner />
      </Center>
    );
  }

  if (!notifications) {
    return null;
  }

  if (notifications.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        No new notifications
      </Alert>
    );
  }

  return (
    <SimpleGrid gap="5px">
      {notifications.map((i) => (
        <Card key={i.id}>
          <CardBody
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text>{i.text}</Text>
            {!i.seen && (
              <Button
                variant="ghost"
                fontWeight="700"
                size="sm"
                color="teal.400"
                onClick={handleNotificationSeen(i.id)}
              >
                New
              </Button>
            )}
          </CardBody>
        </Card>
      ))}
    </SimpleGrid>
  );
};

export default NotificationList;
