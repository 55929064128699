import { useEffect, useRef, useState } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Spinner,
  Stack,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
  useToast,
  Center,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";

import {
  deleteTaxPaymentBySlug,
  getTaxPaymentBySlug,
  downloadAMS,
} from "api/taxPayments";
import { formatBAM } from "utils/helpers";

const TaxPaymentDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [taxPayment, setTaxPayment] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  useEffect(() => {
    const fetchTaxPayment = async () => {
      try {
        setLoading(true);
        const result = await getTaxPaymentBySlug(slug);
        setTaxPayment(result);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchTaxPayment();
  }, [slug]);

  const handleDelete = async () => {
    try {
      await deleteTaxPaymentBySlug(slug);

      toast({
        title: "Tax payment deleted",
        status: "success",
        isClosable: true,
        duration: 5000,
      });
      navigate("/tax-payments");
    } catch (e) {
      console.error(e);
      toast({
        title: "Error deleting tax payment",
        status: "error",
        isClosable: true,
        duration: 5000,
      });
    }
  };

  const generateAMS = async () => {
    const url = await downloadAMS(taxPayment.id);
    window.open(url, "_blank");
  };

  if (loading || !taxPayment) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={4} align="stretch">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/tax-payments">
            Tax payments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{taxPayment.slug}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Card>
        <CardHeader>
          <Heading size="md">
            Tax payment for {taxPayment.payment.client.name}
          </Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Payment At
              </Heading>
              <Text pt="2" fontSize="sm">
                {format(new Date(taxPayment.payment.paymentAt), "yyyy LLL dd")}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Amount
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.payment.amount)}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading size="md">Administration</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Taxable amount
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.taxableAmount)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Health insurance
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.healthInsurance)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Tax base
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.taxBase)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Tax amount
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.taxAmount)}
              </Text>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Generate AMS
                </Heading>
                <Text pt="2" fontSize="sm">
                  Create PDF document
                </Text>
              </Box>
              <Button colorScheme="blue" onClick={generateAMS}>
                Generate
              </Button>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Heading size="md">Bank</Heading>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                Tax
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.taxPayment)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                ZDK
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.taxPaymentCanton)}
              </Text>
            </Box>
            <Box>
              <Heading size="xs" textTransform="uppercase">
                FBiH
              </Heading>
              <Text pt="2" fontSize="sm">
                {formatBAM(taxPayment.taxPaymentEntity)}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Heading size="xs" textTransform="uppercase">
                  Delete
                </Heading>
                <Text pt="2" fontSize="sm">
                  Delete tax payment
                </Text>
              </Box>
              <Button colorScheme="red" onClick={onOpen}>
                Delete
              </Button>

              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                      Delete tax payment
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button colorScheme="red" onClick={handleDelete} ml={3}>
                        Delete
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default TaxPaymentDetail;
