import { Box, Button, Card, CardBody, Text } from "@chakra-ui/react";

const SettingsItem = ({ title, description, actionName, onActionClick }) => {
  return (
    <Card>
      <CardBody>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Text as="b">{title}</Text>
            <Text>{description}</Text>
          </Box>
          <Box>
            <Button colorScheme="teal" onClick={onActionClick}>
              {actionName}
            </Button>
          </Box>
        </Box>
      </CardBody>
    </Card>
  );
};

export default SettingsItem;
