import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { parseErrorResponse } from "api/helpers";
import NoteForm from "./NoteForm";
import { useEffect } from "react";

const NoteEdit = ({ note, onUpdate, onDelete, onCancel }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    if (note) {
      onOpen();
    } else {
      onClose();
    }
  }, [note, onOpen, onClose]);

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleUpdate = async (values) => {
    try {
      await onUpdate(values);
      toast({
        title: "Note update successfully",
        status: "success",
      });
      handleCancel();
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Unable to update note"),
        description: "Please try again",
        status: "error",
      });
    }
  };

  const handleDelete = async () => {
    try {
      await onDelete();
      toast({
        title: "Note removed successfully",
        status: "success",
      });
      handleCancel();
    } catch (e) {
      toast({
        title: parseErrorResponse(e, "Unable to remove note"),
        description: "Please try again",
        status: "error",
      });
    }
  };

  if (!note) {
    return null;
  }

  return (
    <Modal onClose={handleCancel} isOpen={isOpen} isCentered size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit note</ModalHeader>
        <ModalCloseButton />
        <NoteForm
          values={{
            title: note.title,
            content: note.content,
          }}
          onSubmit={handleUpdate}
          onCancel={handleCancel}
          onDelete={handleDelete}
        />
      </ModalContent>
    </Modal>
  );
};

export default NoteEdit;
