const NON_FIELD_ERROR_NAME = "non_field_errors";
const MESSAGE_FIELD_NAME = "detail";

function isResponseDataInvalid(data) {
  return !data || !(typeof data === "object" && data !== null);
}

export function parseErrorResponse(error, defaultMessage) {
  const data = error?.response?.data;

  if (isResponseDataInvalid(data)) {
    return defaultMessage;
  }

  if (MESSAGE_FIELD_NAME in data) {
    return data[MESSAGE_FIELD_NAME];
  }

  if (NON_FIELD_ERROR_NAME in data) {
    const messages = data[NON_FIELD_ERROR_NAME];

    if (Array.isArray(messages)) {
      return messages[0]; // Get first message
    }

    return messages;
  }

  if (Array.isArray(data)) {
    return data[0]; // Get first message
  }

  return defaultMessage;
}
