import humps from "humps";

import api from "./config";

export async function getPensionInsurancesForYear(year) {
  const response = await api.get(`payments/pension-insurances/year/${year}/`);
  return humps.camelizeKeys(response.data);
}

export async function createPensionInsurance(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("payments/pension-insurances/", body);
  return humps.camelizeKeys(response.data);
}

export async function deletePensionInsurance(id) {
  const response = await api.delete(`payments/pension-insurances/${id}/`);
  return humps.camelizeKeys(response.data);
}

export async function getPensionInsurance(id) {
  const response = await api.get(`payments/pension-insurances/${id}/`);
  return humps.camelizeKeys(response.data);
}
