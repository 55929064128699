import { useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Flex, useToast } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import { getTaxPaymentsForYear } from "api/taxPayments";
import TaxPaymentList from "./TaxPaymentList";
import TaxPaymentFilter from "./TaxPaymentFilter";
import TaxPaymentImport from "./TaxPaymentImport";
import { TaxPaymentStats } from "./TaxPaymentStats";
import GPD from "./GPD";

function TaxPayments() {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
  });
  const [taxPayments, setTaxPayments] = useState();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTaxPayments = async () => {
      try {
        setLoading(true);
        const { year } = filters;
        const response = await getTaxPaymentsForYear(year);
        setTaxPayments(response);
      } catch (e) {
        toast({
          title: "Unable to load tax payments",
          description: "Please try again",
          status: "error",
          isClosable: true,
          duration: 2000,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTaxPayments();
  }, [filters, toast]);

  const handleFiltersChange = (newFilters) => {
    setFilters({ ...filters, ...newFilters });
  };

  const createNewTaxPayment = () => navigate("/tax-payments/new");

  return (
    <Box display="flex" flexDirection="column" gap="5">
      <Flex justifyContent={{ sm: "flex-start", md: "flex-end" }}>
        <ButtonGroup isAttached>
          <Button
            leftIcon={<AddIcon />}
            colorScheme="teal"
            onClick={createNewTaxPayment}
          >
            Add tax payment
          </Button>
          <TaxPaymentImport />
        </ButtonGroup>
      </Flex>
      <Box>
        <TaxPaymentFilter
          filters={filters}
          onFilterChange={handleFiltersChange}
        />
      </Box>
      <Box>
        <TaxPaymentStats year={filters.year} />
      </Box>
      <Box>
        <TaxPaymentList taxPayments={taxPayments} loading={loading} />
      </Box>
      <Box>
        <GPD taxPayments={taxPayments} />
      </Box>
    </Box>
  );
}

export default TaxPayments;
