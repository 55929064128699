import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Badge, Box } from "@chakra-ui/react";
import { FiBell } from "react-icons/fi";

import { notificationsCheck } from "api/notifications";

const NotificationBell = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await notificationsCheck();
        setData(result);
      } catch (e) {
        console.error(e);
      }
    };

    fetchData();
  }, []);

  const goToNotifications = () => {
    setData(null);
    navigate("/notifications");
  };

  if (!data || !data.hasNew) {
    return (
      <IconButton
        size="lg"
        variant="ghost"
        aria-label="open menu"
        icon={<FiBell />}
        onClick={goToNotifications}
      />
    );
  }

  return (
    <IconButton
      size="lg"
      variant="ghost"
      icon={
        <Box position="relative">
          <FiBell />
          <Badge
            position="absolute"
            bottom="3"
            left="3"
            size="sm"
            colorScheme="red"
            borderRadius="50%"
          >
            {data.count}
          </Badge>
        </Box>
      }
      onClick={goToNotifications}
    />
  );
};

export default NotificationBell;
