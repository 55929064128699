import {
  Alert,
  AlertIcon,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns";

const NoteList = ({ notes, loading, onEdit }) => {
  const handleEdit = (id) => () => {
    onEdit(id);
  };

  if (loading) {
    return (
      <Center marginTop={10}>
        <Spinner />
      </Center>
    );
  }

  if (!notes) {
    return null;
  }

  if (notes.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        Notes not found!
      </Alert>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Title</Th>
            <Th>Last Modified At</Th>
            <Th>Content</Th>
          </Tr>
        </Thead>
        <Tbody>
          {notes.map((n) => (
            <Tr key={n.id}>
              <Td onClick={handleEdit(n.id)} color="blue.400" cursor="pointer">
                {n.title}
              </Td>
              <Td>{format(new Date(n.updatedAt), "PPP hh:mm")}</Td>
              <Td>
                {n.content.slice(0, 20)} {n.content.length > 20 && "..."}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default NoteList;
