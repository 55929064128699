import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import Root from "components/root";
import { StoreProvider } from "store/StoreContext";

function App() {
  return (
    <ChakraProvider
      toastOptions={{
        defaultOptions: {
          position: "bottom",
          isClosable: true,
          duration: 2000,
        },
      }}
    >
      <StoreProvider>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </StoreProvider>
    </ChakraProvider>
  );
}

export default App;
