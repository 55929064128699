import { useEffect, useState } from "react";
import { Box, Select } from "@chakra-ui/react";

import { useStore } from "store/StoreContext";
import { getClientsSearch } from "api/clients";

function BillFilter({ filters, onFilterChange }) {
  const [{ yearsFilter }] = useStore();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const result = await getClientsSearch(true);
        setClients(result);
      } catch (e) {
        console.error(e);
      }
    };

    fetchClients();
  }, []);

  const handleSelectYear = (e) => {
    onFilterChange({ year: e.target.value });
  };

  const handleSelectClient = (e) => {
    onFilterChange({ client: e.target.value });
  };

  return (
    <Box display="flex" gap="5">
      <Select
        width={150}
        placeholder="Select year"
        value={filters.year}
        onChange={handleSelectYear}
      >
        {yearsFilter.map((yf) => (
          <option key={yf} value={yf}>
            {yf}
          </option>
        ))}
      </Select>
      <Select
        width={250}
        placeholder="Select client"
        value={filters.client}
        onChange={handleSelectClient}
      >
        {clients.map((c) => (
          <option key={c.id} value={c.id}>
            {c.name}
          </option>
        ))}
      </Select>
    </Box>
  );
}

export default BillFilter;
