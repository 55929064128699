import humps from "humps";

import api from "./config";

export async function getClients() {
  const response = await api.get("payments/clients/");
  return humps.camelizeKeys(response.data);
}

export async function createClient(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("payments/clients/", body);
  return humps.camelizeKeys(response.data);
}

export async function deleteClient(id) {
  await api.delete(`payments/clients/${id}/`);
}

export async function getClient(id) {
  const response = await api.get(`payments/clients/${id}/`);
  return humps.camelizeKeys(response.data);
}

export async function editClient(id, data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.patch(`payments/clients/${id}/`, body);
  return humps.camelizeKeys(response.data);
}

export async function getClientsSearch(isGovernment) {
  const params = humps.decamelizeKeys({ isGovernment });
  const response = await api.get(`payments/clients/search/`, { params });
  return humps.camelizeKeys(response.data);
}
