import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

import { getClientsSearch } from "api/clients";
import { INSERT_METHODS } from "utils/constants";

const HealthInsuranceForm = ({ values, onSubmit, onCancel }) => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const result = await getClientsSearch(true);
        setClients(result);
      } catch (e) {
        console.error(e);
      }
    };

    fetchClients();
  }, []);

  return (
    <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
      {(props) => (
        <Form>
          <ModalBody>
            <Field name="client">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.client && form.touched.client}
                >
                  <FormLabel>Client</FormLabel>
                  <Select {...field} placeholder="Select client">
                    {clients.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.client}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="startPeriod">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.startPeriod && form.touched.startPeriod
                  }
                >
                  <FormLabel>Start period</FormLabel>
                  <Input {...field} type="month" />
                  <FormErrorMessage>{form.errors.startPeriod}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="endPeriod">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.endPeriod && form.touched.endPeriod}
                >
                  <FormLabel>End period</FormLabel>
                  <Input {...field} type="month" />
                  <FormErrorMessage>{form.errors.endPeriod}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="amount">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.amount && form.touched.amount}
                >
                  <FormLabel>Amount</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="insertMethod">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.insertMethod && form.touched.insertMethod
                  }
                >
                  <FormLabel>Insert method</FormLabel>
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <Radio {...field} value={INSERT_METHODS.RAISE}>
                        Raise if exists
                      </Radio>
                      <Radio {...field} value={INSERT_METHODS.OVERWRITE}>
                        Overwrite
                      </Radio>
                      <Radio {...field} value={INSERT_METHODS.IGNORE}>
                        Ignore conflicts
                      </Radio>
                    </Stack>
                  </RadioGroup>
                  <FormErrorMessage>
                    {form.errors.insertMethod}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={props.isSubmitting}
            >
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default HealthInsuranceForm;
