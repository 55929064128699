import { useEffect, useState, useCallback } from "react";
import { Box, Flex, useToast } from "@chakra-ui/react";

import { createBill, getBills } from "api/bills";
import BillList from "./BillList";
import BillCreate from "./BillCreate";
import BillFilter from "./BillFilter";

const Bills = () => {
  const [loading, setLoading] = useState();
  const [bills, setBills] = useState();
  const toast = useToast();
  const [filters, setFilters] = useState({
    year: new Date().getFullYear(),
    client: undefined,
  });

  const fetchBills = useCallback(async () => {
    try {
      setLoading(true);
      const { results } = await getBills(filters);
      setBills(results);
    } catch (e) {
      toast({
        title: "Unable to load bills",
        description: "Please try again",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [toast, filters]);

  useEffect(() => {
    fetchBills();
  }, [fetchBills]);

  const handleCreate = async (values) => {
    try {
      const response = await createBill(values);
      setBills([response, ...bills]);
    } catch (e) {
      toast({
        title: "Unable to create bill",
        description: "Please try again",
        status: "error",
      });
    }
  };

  const handleFiltersChange = (updatedFilter) => {
    setFilters({ ...filters, ...updatedFilter });
  };

  return (
    <Box display="flex" flexDirection="column" gap="5">
      <Flex justifyContent={{ sm: "flex-start", md: "flex-end" }}>
        <BillCreate onSubmit={handleCreate} />
      </Flex>
      <Box>
        <BillFilter filters={filters} onFilterChange={handleFiltersChange} />
      </Box>
      <Box>
        <BillList bills={bills} loading={loading} />
      </Box>
    </Box>
  );
};

export default Bills;
