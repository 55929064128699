import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

import { createTaxPayment } from "api/taxPayments";
import Currency from "./Currency";
import { formatBAM } from "utils/helpers";
import { getClientsSearch } from "api/clients";

function TaxPaymentCreate() {
  const navigate = useNavigate();
  const toast = useToast();
  const [clients, setClients] = useState([]);
  const [dollarDate, setDollarDate] = useState();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const result = await getClientsSearch(false);
        setClients(result);
      } catch (e) {
        console.error(e);
      }
    };

    fetchClients();
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      const { payment } = await createTaxPayment({
        payment: { ...values, clientId: values.client },
      });
      toast({
        title: `Tax payment successfully created`,
        description: `Client ${payment.client.name} with amount ${formatBAM(
          payment.amount
        )}`,
        status: "success",
        isClosable: true,
        duration: 5000,
      });
      navigate("/tax-payments");
    } catch (e) {
      toast({
        title: "Unable to create tax payment",
        description: "Please try again",
        status: "error",
      });
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleDateChange = (defaultCallback) => async (e) => {
    defaultCallback(e);
    setDollarDate(e.target.value);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/tax-payments">
            Tax payments
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>New</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Formik
        initialValues={{ paymentAt: "", client: "", amount: 0 }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Field name="client">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.client && form.touched.client}
                >
                  <FormLabel>Client</FormLabel>
                  <Select {...field} placeholder="Select client">
                    {clients.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.client}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="paymentAt">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.paymentAt && form.touched.paymentAt}
                >
                  <FormLabel>Payment At</FormLabel>
                  <Input
                    {...field}
                    onChange={handleDateChange(field.onChange)}
                    type="date"
                  />
                  <FormErrorMessage>{form.errors.paymentAt}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="amount">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.amount && form.touched.amount}
                >
                  <FormLabel>Amount</FormLabel>
                  <Input {...field} />
                  <FormErrorMessage>{form.errors.amount}</FormErrorMessage>
                  <FormHelperText>
                    <Currency date={dollarDate} />
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
            <Button
              mt={4}
              colorScheme="teal"
              isLoading={props.isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </VStack>
  );
}

export default TaxPaymentCreate;
