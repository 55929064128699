import humps from "humps";

import api from "./config";

export async function getBills(params) {
  const response = await api.get("payments/bills/", { params });
  return humps.camelizeKeys(response.data);
}

export async function createBill(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("payments/bills/", body);
  return humps.camelizeKeys(response.data);
}

export async function updateBillStatus(id) {
  const response = await api.put(`payments/bills/${id}/status/`);
  return humps.camelizeKeys(response.data);
}

export async function getBill(id) {
  const response = await api.get(`payments/bills/${id}/`);
  return humps.camelizeKeys(response.data);
}

export async function deleteBill(id) {
  const response = await api.delete(`payments/bills/${id}/`);
  return humps.camelizeKeys(response.data);
}
