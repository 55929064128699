import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { formatBAM } from "utils/helpers";

function TaxPaymentList({ taxPayments, loading }) {
  if (loading) {
    return (
      <Center marginTop={10}>
        <Spinner />
      </Center>
    );
  }

  if (!taxPayments) {
    return null;
  }

  const taxPaymentsLength = taxPayments.length;

  if (taxPaymentsLength === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        Tax payments not found!
      </Alert>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Client</Th>
            <Th>Payment At</Th>
            <Th isNumeric>Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {taxPayments.map((tp, index) => (
            <Tr key={tp.id}>
              <Td>
                <Link
                  to={`/tax-payments/${tp.slug}`}
                  as={RouterLink}
                  color={"blue.400"}
                >
                  {taxPaymentsLength - index}
                </Link>
              </Td>
              <Td>{tp.payment.client.name}</Td>
              <Td>{format(new Date(tp.payment.paymentAt), "MMMM dd")}</Td>
              <Td isNumeric>{formatBAM(tp.payment.amount)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default TaxPaymentList;
