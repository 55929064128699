import humps from "humps";

import api from "./config";

export async function getTaxPaymentsForYear(year) {
  const response = await api.get(`payments/tax-payments/year/${year}/`);
  return humps.camelizeKeys(response.data);
}

export async function createTaxPayment(data) {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("payments/tax-payments/", body);
  return humps.camelizeKeys(response.data);
}

export async function getTaxPaymentBySlug(slug) {
  const response = await api.get(`payments/tax-payments/${slug}/`);
  return humps.camelizeKeys(response.data);
}

export async function deleteTaxPaymentBySlug(slug) {
  const response = await api.delete(`payments/tax-payments/${slug}/`);
  return humps.camelizeKeys(response.data);
}

export async function importTaxPayments(data) {
  const response = await api.post(`payments/tax-payments/import/`, data);
  return humps.camelizeKeys(response.data);
}

export async function getTaxPaymentStats(year) {
  const response = await api.get(`payments/tax-payments/stats/${year}/`);
  return humps.camelizeKeys(response.data);
}

export async function downloadAMS(id) {
  return `${api.getUri()}/payments/tax-payments/${id}/generate-ams`;
}
