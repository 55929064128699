import humps from "humps";

import api from "./config";

export const login = async (data) => {
  const body = humps.decamelizeKeys(data);
  const response = await api.post("/auth/login/", body);
  return humps.camelizeKeys(response.data);
};

export const logout = async () => {
  const response = await api.get("/auth/logout/");
  return humps.camelizeKeys(response.data);
};

export const getSession = async () => {
  const response = await api.get("/auth/session/");
  return humps.camelizeKeys(response.data);
};

export const getCSRF = async () => {
  const response = await api.get("/auth/csrf/");
  return humps.camelizeKeys(response.data);
};
