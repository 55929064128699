import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Tag,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { formatBAM } from "utils/helpers";

const ClientList = ({ clients, loading, onDelete, onEdit }) => {
  if (loading) {
    return (
      <Center marginTop={10}>
        <Spinner />
      </Center>
    );
  }

  if (!clients) {
    return null;
  }

  if (clients.length === 0) {
    return (
      <Alert status="info">
        <AlertIcon />
        Clients not found!
      </Alert>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Address</Th>
            <Th>State</Th>
            <Th>Bank Number</Th>
            <Th>Employment</Th>
            <Th isNumeric>Income</Th>
            <Th isNumeric>Outcome</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {clients.map((c) => (
            <Tr key={c.id}>
              <Td>
                {c.name} {c.isGovernment && <Tag color="teal">Government</Tag>}{" "}
              </Td>
              <Td>{c.address}</Td>
              <Td>{c.state}</Td>
              <Td>{c.bankNumber}</Td>
              <Td>
                <Box
                  display="flex"
                  gap="5px"
                  flexDirection="column"
                  alignItems="start"
                >
                  {c.employmentHistory.length ? (
                    c.employmentHistory.map((eh) => (
                      <Tag key={eh.id}>
                        {format(new Date(eh.startDate), "yyyy MMMM dd")} -{" "}
                        {eh.endDate
                          ? format(new Date(eh.endDate), "yyyy MMMM dd")
                          : "Current"}
                      </Tag>
                    ))
                  ) : (
                    <Tag>No employment</Tag>
                  )}
                </Box>
              </Td>
              <Td isNumeric>{formatBAM(c.income || 0)}</Td>
              <Td isNumeric>{formatBAM(c.outcome || 0)}</Td>
              <Td>
                <Box display="flex" gap="10px" justifyContent="flex-end">
                  <IconButton
                    variant="ghost"
                    icon={<EditIcon color="blue.400" />}
                    onClick={onEdit(c.id)}
                  />
                  <IconButton
                    variant="ghost"
                    icon={<DeleteIcon color="red.400" focusable />}
                    onClick={onDelete(c.id)}
                  />
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ClientList;
