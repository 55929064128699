import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Field } from "formik";

const ClientEmploymentHistory = ({ employmentHistory, onAdd, onRemove }) => {
  const handleAddNew = () => {
    onAdd({ startDate: undefined, endDate: undefined });
  };

  const handleRemove = (index) => () => {
    onRemove(index);
  };

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Start date</Th>
            <Th>End date</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {employmentHistory.map((eh, index) => (
            <Tr key={index}>
              <Td>
                <Field name={`employmentHistory[${index}].startDate`}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.startDate && form.touched.startDate
                      }
                    >
                      <Input {...field} type="date" />
                      <FormErrorMessage>
                        {form.errors.startDate}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Td>
              <Td>
                <Field name={`employmentHistory[${index}].endDate`}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.endDate && form.touched.endDate}
                    >
                      <Input {...field} type="date" />
                      <FormErrorMessage>{form.errors.endDate}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Td>
              <Td>
                <IconButton
                  variant="ghost"
                  icon={<DeleteIcon color="red.400" focusable />}
                  onClick={handleRemove(index)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan="3">
              <Button variant="ghost" size="sm" onClick={handleAddNew}>
                Add new employment
              </Button>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default ClientEmploymentHistory;
