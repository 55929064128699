import { useRef } from "react";
import { IconButton, useToast } from "@chakra-ui/react";
import { CiImport } from "react-icons/ci";
import { importTaxPayments } from "api/taxPayments";

const TaxPaymentImport = () => {
  const toast = useToast();
  const inputRef = useRef();

  const onChangeFile = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];

    try {
      const form = new FormData();
      form.append("file", file);

      await importTaxPayments(form);
    } catch (e) {
      toast({
        title: "Unable to import tax payments",
        description: "Please try again",
        status: "error",
      });
    }
  };

  return (
    <>
      <IconButton
        aria-label="Import"
        colorScheme="orange"
        icon={<CiImport />}
        onClick={() => inputRef.current.click()}
      />
      <input
        type="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={onChangeFile}
      />
    </>
  );
};

export default TaxPaymentImport;
