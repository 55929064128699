import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";

import { getCurrency } from "api/currency";

const Currency = ({ date }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchCurrency = async () => {
      try {
        setError(null);
        setLoading(true);
        const currency = await getCurrency(date);

        setValue(currency.value);
      } catch (e) {
        console.error(e);
        setError("Error fetching currency");
      } finally {
        setLoading(false);
      }
    };

    if (date) {
      fetchCurrency();
    }
  }, [date]);

  if (loading) {
    return <Spinner size="xs" />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!value) {
    return null;
  }

  return <div>Currency 1 $ is {value} BAM</div>;
};

export default Currency;
